<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <small>© Copyright 2023 by Jay. All rights reserved.</small>
      <br>
      <small>Designed by Jay.</small>
      <!--      <small>  Vue.js, HTML5, SCSS engineered by <a href="https://github.com/dsaves" target="_blank">dsaves.</a></small>-->
    </div>
  </footer>
</template>

<script setup
        lang="ts"></script>

<style lang="scss"
       scoped></style>
